import { RouteRecordRaw } from "vue-router"

import IPPool from "./ipPool/IPPool.vue";
import IPPoolEditor from "./ipPool/IPPoolEditor.vue";
import VirtualNetwork from "./VirtualNetwork.vue";
import VirtualNetworkEditor from "./VirtualNetworkEditor.vue";
import LogicalInterface from "./logicalInterface/LogicalInterface.vue";
import LogicalInterfaceEditor from "./logicalInterface/LogicalInterfaceEditor.vue";
import IpPoolGroup from "@/views/virtualNetwork/ipPoolGroup/IpPoolGroup.vue";
import IpPoolGroupEditor from "@/views/virtualNetwork/ipPoolGroup/IpPoolGroupEditor.vue";

const routes: Array<RouteRecordRaw> = [
    // IP-Pool
    {
        path: "/ipPool",
        name: "ipPool",
        component: IPPool,
        meta: { menu: "ipPool" },
    },
    {
        path: "/ipPool/new",
        name: "ipPoolEditorNew",
        component: IPPoolEditor,
        meta: { menu: "ipPool" },
    },
    {
        path: "/ipPool/:id",
        name: "ipPoolEditor",
        component: IPPoolEditor,
        meta: { menu: "ipPool" },
        props: true,
    },
    //IP-Pool Group
    {
        path: "/ipPoolGroup",
        name: "ipPoolGroup",
        component: IpPoolGroup,
        meta: { menu: "ipPoolGroup" },
    },
    {
        path: "/ipPoolGroup/new",
        name: "ipPoolGroupEditorNew",
        component: IpPoolGroupEditor,
        meta: { menu: "ipPoolGroup" },
    },
    {
        path: "/ipPoolGroup/:id",
        name: "ipPoolGroupEditor",
        component: IpPoolGroupEditor,
        meta: { menu: "ipPoolGroup" },
        props: true,
    },
    // Virtual-Network
    {
        path: "/virtualNetwork",
        name: "virtualNetwork",
        component: VirtualNetwork,
        meta: { menu: "management" },
    },
    {
        path: "/virtualNetwork/new",
        name: "virtualNetworkEditorNew",
        component: VirtualNetworkEditor,
        meta: { menu: "management" },
    },
    {
        path: "/virtualNetwork/:id",
        name: "virtualNetworkEditor",
        component: VirtualNetworkEditor,
        meta: { menu: "management" },
        props: true,
    },
    // Logical-Interface
    {
        path: "/logicalInterface",
        name: "logicalInterface",
        component: LogicalInterface,
        meta: { menu: "management" },
    },
    {
        path: "/logicalInterface/new",
        name: "logicalInterfaceEditorNew",
        component: LogicalInterfaceEditor,
        meta: { menu: "management" },
    },
    {
        path: "/logicalInterface/:id",
        name: "logicalInterfaceEditor",
        component: LogicalInterfaceEditor,
        meta: { menu: "management" },
        props: true,
    }
]

export default routes