
import { MenuItem } from "primevue/menuitem";
import { defineComponent } from "vue";
import { Language } from "./types/dto";
import { defaultLocale, switchLocale } from "./_config/ui-framework";
import { PanelMenuPanelOpenEvent } from "primevue/panelmenu"
import { RouteLocationNormalizedLoaded } from "vue-router"

// @ts-ignore
import config from './assets/env.js'

export default defineComponent({
  data() {
    return {
      isExpanded: this.$cx.getStorageBool("cx.nav", true),
      toolbarExpandedKeys: {} as { [key: string]: boolean },
      selectedLanguage: defaultLocale,
      languages: [
        { name: "DE", value: Language.DE },
        { name: "EN", value: Language.EN },
        { name: "FR", value: Language.FR },
      ],
    };
  },
  methods: {
    onToggleNav() {
      this.isExpanded = !this.isExpanded
      localStorage.setItem("cx.nav", this.isExpanded.toString())
    },
    onLanguageChanged($event: any) {
      switchLocale(this.$primevue, $event.value);
    },
    onReloadDropdown() {
      this.$store.state.ui.dropdownOptions.reloadFn();
      (this.$refs as any).dropdownOptions.hide();
    },
    onEditDropdownEntry() {
      this.$cx.goToByIdNewTab(
        this.$store.state.ui.dropdownOptions.options.route,
        this.$store.state.ui.dropdownOptions.options.getId(
          this.$store.state.ui.dropdownOptions.value))
    },
    onCreateDropdownEntry() {
      this.$cx.goToNewTab(this.$store.state.ui.dropdownOptions.options.route + 'New')
    },
    showUserOverlay(ev: any) {
      (this.$refs as any)["userOverlay"].toggle(ev)
    },
    onLogout() {
      this.$cx.confirm("Logout bestätigen", "Möchten Sie sich wirklich abmelden?", () => {
        this.$auth.logout()
      })
    },

    isHighlighted(name: string, tab?: number)
    {
      const routeMenu = this.$route.meta?.menu?.toString().toLowerCase()

      if (!routeMenu || !name)
        return ""

      if (routeMenu == name.toLowerCase())
        if(tab == undefined)
          return 'cx-menu-highlight'
        else if(this.$route.query.tab == tab.toString())
          return 'cx-menu-highlight'

      return ""
    },

    onMenuRootPanelOpen(event: PanelMenuPanelOpenEvent)
    {
      const openedMenuItem: MenuItem = event.item
      if(!openedMenuItem.key) return
      if (openedMenuItem)
        Object.keys(this.toolbarExpandedKeys).forEach(expandedKey =>
        {
          if (expandedKey != openedMenuItem.key)
            this.toolbarExpandedKeys[expandedKey] = false
        })
    },

    getMenuChildren(menuItem: MenuItem): MenuItem[]
    {
      if(menuItem.items)
      {
        let children:MenuItem[] = []
        menuItem.items.forEach(childMenuItem =>
        {
          children.push(childMenuItem)

          if(childMenuItem.items)
            children = children.concat(this.getMenuChildren(childMenuItem))
        })
        return children
      }
      return []
    },

    updateMenuAfterNavigation(routeObject:RouteLocationNormalizedLoaded)
    {
      if(!routeObject.meta.menu) return
      const searchKey = routeObject.meta.menu?.toString().toLowerCase()

      if(!Object.keys(this.getToolbarItemsChildMap).includes(searchKey))
        return
      
      const newToolbarExpandedKeys = {[searchKey]: true}
      
      // get all keys from getToolbarItemsChildMap where the key value array contains the current route name
      const keys = Object.keys(this.getToolbarItemsChildMap)
        .filter(key => this.getToolbarItemsChildMap[key].includes(searchKey))
      
      keys.forEach(key =>
      {
        newToolbarExpandedKeys[key] = true
      })

      this.toolbarExpandedKeys = newToolbarExpandedKeys
    }

  },
  watch: {
    "$store.state.ui.dropdownOptions": function () {
      (this.$refs as any).dropdownOptions.toggle(this.$store.state.ui.dropdownOptions.event)
    },

    $route (to: RouteLocationNormalizedLoaded)
    {
      this.updateMenuAfterNavigation(to)
    },

  },

  created()
  {
    this.updateMenuAfterNavigation(this.$route)
  },

  computed: {
    getToolbarItems() {
      return [
        {
          key: 'dashboard',
          label: this.$t("appMenu", 'dashboards'),
          icon: "pi pi-home",
          class: "cx-panel-header",
          items: [
            {
              key: 'ipoverview',
              label: this.$t("common", "overview"),
              icon: "pi pi-list",
              class: this.isHighlighted("dashboard"),
              to: "/",
            },
            {
              key: 'software',
              label: this.$t("softwareManagement", 'lb'),
              icon: "pi pi-cloud-download",
              class: this.isHighlighted("software", 1),
              to: { name: "softwareManagement", query: { tab: 1 } },
            },
            {
              key: 'software',
              label: this.$t('softwareManagement', 'softwareComplianceTab') ,
              icon: "pi pi-check-square",
              class: this.isHighlighted("software", 2),
              to: { name: "softwareManagement", query: { tab: 2 } },
            },
            {
              key: 'software',
              label: this.$t('softwareManagement', 'configComplianceTab'),
              icon: "pi pi-sliders-v",
              class: this.isHighlighted("software", 3),
              to: { name: "softwareManagement", query: { tab: 3 } },

            },
          ]
        },
        {
          key: 'location',
          label: this.$t("location", "lb_pl"),
          icon: "pi pi-building",
          class: this.isHighlighted("location"),
          to: "/location",
        },
        {
          key: 'goldenconfig',
          label: "Golden Config",
          icon: "pi pi-cog",
          class: "cx-panel-header",
          items: [
            {
              key: 'configuration',
              label: this.$t("appMenu", "configuration"),
              icon: "pi pi-book",
              class: this.isHighlighted("configuration"),
              to: "/configuration",
            },
            {
              key: 'compliance',
              label: this.$t("compliance", "management"),
              icon: "pi pi-book",
              class: this.isHighlighted("compliance"),
              to: "/compliance-overview",
            },
            {
              key: 'variable',
              label: this.$t("variable", "lb_pl"),
              icon: "pi pi-code",
              class: this.isHighlighted("variable"),
              to: "/variables",
            }
          ]
        },
        {
          key: 'ip_management',
          label: this.$c("ipManagement"),
          icon: "pi pi-book",
          class: "cx-panel-header",
          items: [
            {
              key: 'ippool',
              label: this.$t("ipPool", "lb_pl"),
              description: "IP-Adressenverwaltung",
              class: this.isHighlighted("ippool"),
              icon: "pi pi-user",
              to: "/ipPool",
            },
            {
              key: 'ippoolgroup',
              label: this.$t("ipPoolGroup", "lb_pl"),
              description: "IP-Pool-Gruppen-Verwaltung",
              class: this.isHighlighted("ippoolgroup"),
              icon: "pi pi-users",
              to: "/ipPoolGroup",
            },
          ]
        },
        {
          key: 'jobs',
          label: this.$t("job", "lb_pl"),
          icon: "pi pi-history",
          class: this.isHighlighted("job"),
          to: "/jobs",
        }, {
          key: 'logs',
          label: this.$t("log", "lb_pl"),
          icon: "pi pi-bars",
          class: this.isHighlighted("logs"),
          to: "/logs",
        },
        {
          key: 'codetemplate',
          label: this.$t("appMenu", 'codetemplate'),
          icon: "pi pi-sitemap",
          class: this.isHighlighted("codetemplate"),
          to: "/codeTemplate",
        },
        {
          key: 'manufacturer',
          label: this.$t("manufacturer", 'lb'),
          icon: "pi pi-wrench",
          class: "cx-panel-header",
          items: [
            {
              key: 'cisco',
              label: "Cisco",
              icon: "pi pi-circle",
              class: "cx-panel-header",
              items: [
                {
                  key: 'apics',
                  label: this.$t("aci", 'lb'),
                  icon: "pi pi-bolt",
                  class: this.isHighlighted("apics"),
                  to: "/apics",
                },
                {
                  key: 'meraki',
                  label: this.$t("meraki", 'lb'),
                  icon: "pi pi-map",
                  class: this.isHighlighted("meraki"),
                  to: "/meraki",
                },
              ]
            },
          ]
        },
        {
          key: 'management',
          label: this.$t("management", "lb"),
          class: this.isHighlighted("management"),
          icon: "pi pi-book",
          to: "/management",
        },
        {
          label: this.$t("common", "collapse"),
          icon: 'pi' + (this.isExpanded ? ' pi-angle-double-left' : ' pi-angle-double-right'),
          command: () => this.onToggleNav()
        },
      ] as MenuItem[]
    },

    getToolbarItemsChildMap()
    {
      const map = {} as { [key: string]: string[] }
      this.getToolbarItems.forEach(item =>
      {
        if(item.key)
        {
          const children = this.getMenuChildren(item)
          map[item.key] = children.map(child => child.key||"")

          children.forEach(child =>
          {
            if(child.key)
            {
              const subChildren = this.getMenuChildren(child)
              map[child.key] = subChildren.map(subChild => subChild.key||"")
            }
          })
          
        }
      })
      
      return map
    },
  },
});
