
import {defineComponent, ref} from "vue";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {SoftwareComplianceDto} from "@/types/dto";
import {buildChartDataSet} from "@/views/dashboard/dashboard-utils";

export default defineComponent({
  data() {
    return {
      chartData: [] as any,
      chartPlugin: ChartDataLabels,
      tableData: [] as any,
      tableFilters: ref({
        manufacturerName: {value: null, matchMode: 'contains'},
        deviceModelName: {value: null, matchMode: 'contains'},
        count: {value: null, matchMode: 'contains'},
        valid: {value: null, matchMode: 'contains'},
        invalidTable: {value: null, matchMode: 'contains'},
        percentage: {value: null, matchMode: 'contains'}
      } as { [key: string]: any }),
    };
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.$store.dispatch('dashboard/getSoftwareComplianceData')
          .then((data: any) => {
            this.tableData = data.map((d: SoftwareComplianceDto) => {
              let invalidTable = d.invalid! + d.unknown!
              let totalCount = d.valid! + invalidTable
              let o = {
                ...d, ...{
                  invalidTable: invalidTable,
                  count: totalCount,
                  percentage: Math.round((d.valid! * 100) / totalCount) + "%"
                }
              }
              return o
            })
            this.chartData = []
            data.forEach((d: SoftwareComplianceDto) => {
              let dataSet = buildChartDataSet(this.$t);
              dataSet.labels.push(d.deviceModelName)
              dataSet.datasets[0].data.push(d.valid)
              dataSet.datasets[1].data.push(d.invalid)
              dataSet.datasets[2].data.push(d.unknown)
              this.chartData.push(dataSet)
            })
          })
    }
  },
});
