import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cx-form-v" }
const _hoisted_2 = { class: "cx-dt-dd-option" }
const _hoisted_3 = {
  key: 0,
  class: "flex"
}
const _hoisted_4 = { class: "cx-chips" }
const _hoisted_5 = {
  key: 1,
  class: "flex"
}
const _hoisted_6 = { class: "cx-chips" }
const _hoisted_7 = { class: "grid" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "flex-row" }
const _hoisted_10 = {
  key: 0,
  class: "col-3 dropdown-container"
}
const _hoisted_11 = {
  key: 1,
  class: "col-3"
}
const _hoisted_12 = { class: "col-2 toggle-container" }
const _hoisted_13 = { class: "ml-2 line-height-3" }
const _hoisted_14 = {
  key: 2,
  class: "col-3"
}
const _hoisted_15 = {
  key: 3,
  class: "col-3"
}
const _hoisted_16 = { class: "col-2 toggle-container" }
const _hoisted_17 = { class: "ml-2 line-height-3" }
const _hoisted_18 = { class: "col-2 toggle-container" }
const _hoisted_19 = { class: "ml-2 line-height-3" }
const _hoisted_20 = { class: "flex-row" }
const _hoisted_21 = {
  key: 0,
  class: "col-3"
}
const _hoisted_22 = {
  key: 1,
  class: "col-3"
}
const _hoisted_23 = { class: "col-2 toggle-container" }
const _hoisted_24 = { class: "ml-2 line-height-3" }
const _hoisted_25 = {
  key: 2,
  class: "col-3"
}
const _hoisted_26 = {
  key: 3,
  class: "col-3"
}
const _hoisted_27 = { class: "col-2 toggle-container" }
const _hoisted_28 = { class: "ml-2 line-height-3" }
const _hoisted_29 = { class: "col-2 toggle-container" }
const _hoisted_30 = { class: "ml-2 line-height-3" }
const _hoisted_31 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_cx_dropdown = _resolveComponent("cx-dropdown")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_cx_json_editor = _resolveComponent("cx-json-editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_cx_label, {
      label: _ctx.$c('hostname'),
      v$: _ctx.v$.entity.name,
      approvalState: _ctx.approvalState,
      prop: "name"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputText, {
          placeholder: _ctx.$c('hostname_ph'),
          modelValue: _ctx.entity.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entity.name) = $event)),
          disabled: !_ctx.approvalState.formEnabled
        }, null, 8, ["placeholder", "modelValue", "disabled"])
      ]),
      _: 1
    }, 8, ["label", "v$", "approvalState"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$t('deviceRole', 'lb'),
      v$: _ctx.v$.entity.deviceRole,
      approvalState: _ctx.approvalState,
      prop: "deviceRole"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_cx_dropdown, {
          modelValue: _ctx.entity.deviceRole,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.deviceRole) = $event)),
          store: "deviceRole",
          optionLabel: "name",
          placeholder: _ctx.$t('deviceRole', 'ph_pl'),
          dropdownOptions: {
          route: 'deviceRoleEditor',
          getId: (en) => en?.id,
          editable: true
        },
          disabled: !_ctx.approvalState.formEnabled
        }, null, 8, ["modelValue", "placeholder", "dropdownOptions", "disabled"])
      ]),
      _: 1
    }, 8, ["label", "v$", "approvalState"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$t('deviceTemplate', 'lb'),
      v$: _ctx.v$.entity.deviceTemplate,
      approvalState: _ctx.approvalState,
      prop: "deviceTemplate"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_cx_dropdown, {
          filters: {
        roleId: _ctx.entity.deviceRole?.id,
      },
          modelValue: _ctx.entity.deviceTemplate,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.deviceTemplate) = $event)),
          store: "deviceTemplate",
          optionLabel: "name",
          placeholder: _ctx.$t('deviceTemplate', 'ph_pl'),
          disabled: !_ctx.approvalState.formEnabled,
          dropdownOptions: {
          route: 'deviceTemplateEditor',
          getId: (en) => en?.id,
          editable: true
        }
        }, {
          option: _withCtx(({ option }) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", null, [
                _createElementVNode("strong", null, _toDisplayString(option?.name), 1)
              ]),
              (option?.deviceRoles.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('deviceTemplate', 'roles')), 1),
                    _createElementVNode("div", _hoisted_4, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(option?.deviceRoles, (role, r) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "cx-chip",
                          key: r
                        }, _toDisplayString(role.name), 1))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true),
              (option?.deviceTemplateModels.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('deviceTemplate', 'models')), 1),
                    _createElementVNode("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getModels(option), (deviceModel, dm) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "cx-chip",
                          key: dm
                        }, _toDisplayString(deviceModel.name), 1))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["filters", "modelValue", "placeholder", "disabled", "dropdownOptions"])
      ]),
      _: 1
    }, 8, ["label", "v$", "approvalState"]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createTextVNode(" In-Band Management "),
        _createElementVNode("div", _hoisted_9, [
          (!_ctx.isLogicalIn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_cx_dropdown, {
                  modelValue: _ctx.inBandManagement.physicalPort as Record<string, any>,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.inBandManagement.physicalPort as Record<string, any>) = $event)),
                  store: "devicePort",
                  optionLabel: "name",
                  placeholder: _ctx.$c('devicePort_ph'),
                  disabled: !_ctx.approvalState.formEnabled,
                  class: "dropdown-container"
                }, {
                  option: _withCtx(({ option }) => [
                    _createElementVNode("span", null, _toDisplayString(option?.devicePortType.name) + _toDisplayString(option?.portInterface), 1)
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder", "disabled"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_cx_dropdown, {
                  modelValue: _ctx.inBandManagement.logicalInterface as Record<string, any>,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.inBandManagement.logicalInterface as Record<string, any>) = $event)),
                  store: "logicalInterface",
                  optionLabel: "name",
                  placeholder: _ctx.$t('logicalInterface', 'ph'),
                  disabled: !_ctx.approvalState.formEnabled,
                  class: "dropdown-container"
                }, null, 8, ["modelValue", "placeholder", "disabled"])
              ])),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_InputSwitch, {
              modelValue: _ctx.isLogicalIn,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isLogicalIn) = $event))
            }, null, 8, ["modelValue"]),
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$c('logical')), 1)
          ]),
          (!_ctx.isPoolGroupIn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createVNode(_component_cx_dropdown, {
                  modelValue: _ctx.inBandManagement.ipPool as Record<string, any>,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.inBandManagement.ipPool as Record<string, any>) = $event)),
                  store: "ipPool",
                  optionLabel: "name",
                  placeholder: _ctx.$t('ipPool', 'ph'),
                  disabled: !_ctx.approvalState.formEnabled,
                  class: "dropdown-container"
                }, null, 8, ["modelValue", "placeholder", "disabled"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createVNode(_component_cx_dropdown, {
                  modelValue: _ctx.inBandManagement.ipPoolGroup as Record<string, any>,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.inBandManagement.ipPoolGroup as Record<string, any>) = $event)),
                  store: "ipPoolGroup",
                  optionLabel: "name",
                  placeholder: _ctx.$t('ipPoolGroup', 'ph'),
                  disabled: !_ctx.approvalState.formEnabled,
                  class: "dropdown-container"
                }, null, 8, ["modelValue", "placeholder", "disabled"])
              ])),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_InputSwitch, {
              modelValue: _ctx.isPoolGroupIn,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.isPoolGroupIn) = $event))
            }, null, 8, ["modelValue"]),
            _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('ipPoolGroup', 'short')), 1)
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_InputSwitch, {
              modelValue: _ctx.inBandManagement.isPrimary,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.inBandManagement.isPrimary) = $event)),
              onClick: _ctx.onInPrimaryChanged
            }, null, 8, ["modelValue", "onClick"]),
            _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$c('primary')), 1)
          ])
        ]),
        _createTextVNode(" Out-of-Band Management "),
        _createElementVNode("div", _hoisted_20, [
          (!_ctx.isLogicalOut)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createVNode(_component_cx_dropdown, {
                  modelValue: _ctx.outOfBandManagement.physicalPort as Record<string, any>,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.outOfBandManagement.physicalPort as Record<string, any>) = $event)),
                  store: "devicePort",
                  optionLabel: "name",
                  placeholder: _ctx.$c('devicePort_ph'),
                  disabled: !_ctx.approvalState.formEnabled,
                  class: "dropdown-container"
                }, {
                  option: _withCtx(({ option }) => [
                    _createElementVNode("span", null, _toDisplayString(option?.devicePortType.name) + _toDisplayString(option?.portInterface), 1)
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder", "disabled"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_22, [
                _createVNode(_component_cx_dropdown, {
                  modelValue: _ctx.outOfBandManagement.logicalInterface as Record<string, any>,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.outOfBandManagement.logicalInterface as Record<string, any>) = $event)),
                  store: "logicalInterface",
                  optionLabel: "name",
                  placeholder: _ctx.$t('logicalInterface', 'ph'),
                  disabled: !_ctx.approvalState.formEnabled,
                  class: "dropdown-container"
                }, null, 8, ["modelValue", "placeholder", "disabled"])
              ])),
          _createElementVNode("div", _hoisted_23, [
            _createVNode(_component_InputSwitch, {
              modelValue: _ctx.isLogicalOut,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.isLogicalOut) = $event))
            }, null, 8, ["modelValue"]),
            _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$c('logical')), 1)
          ]),
          (!_ctx.isPoolGroupOut)
            ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                _createVNode(_component_cx_dropdown, {
                  modelValue: _ctx.outOfBandManagement.ipPool as Record<string, any>,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.outOfBandManagement.ipPool as Record<string, any>) = $event)),
                  store: "ipPool",
                  optionLabel: "name",
                  placeholder: _ctx.$t('ipPool', 'ph'),
                  disabled: !_ctx.approvalState.formEnabled,
                  class: "dropdown-container"
                }, null, 8, ["modelValue", "placeholder", "disabled"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_26, [
                _createVNode(_component_cx_dropdown, {
                  modelValue: _ctx.outOfBandManagement.ipPoolGroup as Record<string, any>,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.outOfBandManagement.ipPoolGroup as Record<string, any>) = $event)),
                  store: "ipPoolGroup",
                  optionLabel: "name",
                  placeholder: _ctx.$t('ipPoolGroup', 'ph'),
                  disabled: !_ctx.approvalState.formEnabled,
                  class: "dropdown-container"
                }, null, 8, ["modelValue", "placeholder", "disabled"])
              ])),
          _createElementVNode("div", _hoisted_27, [
            _createVNode(_component_InputSwitch, {
              modelValue: _ctx.isPoolGroupOut,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.isPoolGroupOut) = $event))
            }, null, 8, ["modelValue"]),
            _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$t('ipPoolGroup', 'short')), 1)
          ]),
          _createElementVNode("div", _hoisted_29, [
            _createVNode(_component_InputSwitch, {
              modelValue: _ctx.outOfBandManagement.isPrimary,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.outOfBandManagement.isPrimary) = $event)),
              onClick: _ctx.onOutPrimaryChanged
            }, null, 8, ["modelValue", "onClick"]),
            _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.$c('primary')), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_31, [
        _createVNode(_component_cx_label, {
          label: _ctx.$t('loginProfile', 'lb'),
          approvalState: _ctx.approvalState,
          prop: "loginProfile"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_cx_dropdown, {
              modelValue: _ctx.entity.loginProfile,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.entity.loginProfile) = $event)),
              store: "loginProfile",
              optionLabel: "name",
              placeholder: _ctx.$t('loginProfile', 'ph'),
              dropdownOptions: {
              route: 'loginProfileEditor',
              getId: (en) => en?.id,
              editable: true
            },
              disabled: !_ctx.approvalState.formEnabled
            }, null, 8, ["modelValue", "placeholder", "dropdownOptions", "disabled"])
          ]),
          _: 1
        }, 8, ["label", "approvalState"])
      ])
    ]),
    (!_ctx.isEditing)
      ? (_openBlock(), _createBlock(_component_cx_label, {
          key: 0,
          label: _ctx.$c('serialNumbers'),
          v$: _ctx.v$.serials
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Textarea, {
              placeholder: _ctx.$c('serialNumbers_ph'),
              rows: "3",
              modelValue: _ctx.serials,
              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.serials) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]))
      : _createCommentVNode("", true),
    _createVNode(_component_cx_label, {
      label: _ctx.$c('meta')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_cx_json_editor, {
          modelValue: _ctx.entity.meta,
          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.entity.meta) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["label"])
  ]))
}