

import {defineComponent, ref} from "vue";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {CxTableColumn, CxTableConfig} from "@/types/cx-table-config";
import {ConfigurationComplianceDto} from "@/types/dto";
import {buildChartDataSet} from "@/views/dashboard/dashboard-utils";

export default defineComponent({

  data() {
    return {
      tableData: [] as ConfigurationComplianceDto[],
      tableFilters: ref({
        manufacturerName: {value: null, matchMode: 'contains'},
        deviceModelName: {value: null, matchMode: 'contains'},
        totalEvaluation: {value: null, matchMode: 'contains'},
        totalValid: {value: null, matchMode: 'contains'},
        totalInvalid: {value: null, matchMode: 'contains'},
        totalUnknown: {value: null, matchMode: 'contains'}
      } as { [key: string]: any }),
      chartData: [] as any,
      barChartOptions: {
        responsive: false,
        plugins: {
          legend: {
            labels: {
              color: '#495057',
              font: {
                size: 10
              }
            },
            position: 'bottom'
          },
          datalabels: {
            color: 'white',
            labels: {
              title: {
                color: 'white'
              }
            }
          },
        },
      },

      filters: {
        roles: [],
        models: [],
        families: [],
        locations: [],
        softwareVersions: []
      },

      chartPlugin: ChartDataLabels,
      tabIndex: 0,
    };
  },

  computed: {
    softwareComplianceTableConfig() {
      return new CxTableConfig([
        new CxTableColumn("manufacturerName", this.$t('manufacturer', 'lb')),
        new CxTableColumn("deviceModelName", this.$t('deviceModel', 'lb')),
        new CxTableColumn("totalEvaluation", this.$t('softwareCompliance', 'totalCount')),
        new CxTableColumn("totalValid", this.$t('softwareCompliance', 'isValid')),
        new CxTableColumn("totalInvalid", this.$t('softwareCompliance', 'inValid')),
        new CxTableColumn("totalUnknown", this.$t('softwareCompliance', 'unknown')),
      ]);
    },

  },

  watch: {},

  mounted() {
    this.load();
  },
  methods: {
    load: function () {
      this.$store.dispatch("dashboard/getConfigurationComplianceData", {})
          .then((data: ConfigurationComplianceDto[]) => {
            this.tableData = <any[]>data;

            this.chartData = [];
            data.forEach((d: ConfigurationComplianceDto) => {
              let dataSet = buildChartDataSet(this.$t);
              dataSet.labels.push(d.deviceModelName)
              dataSet.datasets[0].data.push(d.totalValid)
              dataSet.datasets[1].data.push(d.totalInvalid)
              dataSet.datasets[2].data.push(d.totalUnknown)
              this.chartData.push(dataSet)
            });

          });

    },
  },


});

