
import {maxLength, required, vMaxLen} from "@/_config/ui-framework";
import {
  CxNetworkType,
  CxNetworkProtocol,
  IPPoolDto,
  SubnetParamsRequest,
  CxIPPoolType,
  IPPoolVirtualNetworkDto,
  VlanDto,
  IPPoolSubnetDto,
  VirtualNetworkDto
} from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import {defineComponent} from "vue";
import {ipv4NetworkMaskOptions, ipv6NetworkMaskOptions} from "@/_config/network-mask-options";
import {CxTableConfig, CxTableColumn, CxDataType} from "@/types/cx-table-config";
import SubnetEditDialog from "./SubnetEditDialog.vue";


export default defineComponent({
  components: {
    SubnetEditDialog,
  },
  props: {
    id: String,
  },
  setup: () => ({v$: useVuelidate() as any}),
  validations() {
    return {
      entity: {
        name: {required, maxLength: maxLength(vMaxLen.name), $autoDirty: true},
        networkIp: {required, $autoDirty: true},
        networkPrefix: {required, $autoDirty: true},
        subnetPrefix: {required, $autoDirty: true}
      },
    };
  },
  data() {
    return {
      subnet: {
        count: 1,
        vlans: []
      } as SubnetParamsRequest,
      virtualNetwork: {} as VirtualNetworkDto,
      virtualNetworkVlans: [] as VlanDto[],
      vlansForSubnet: [] as VlanDto[],
      vlans: [] as IPPoolVirtualNetworkDto[],
      vlan: {} as IPPoolSubnetDto,
      vlanOptions: [] as VlanDto[],
      isVLSM: false,
      subnets: [] as any[],
      subnetTotalCount: 0,
      entity: {} as IPPoolDto,
      initialState: {} as IPPoolDto,
      networkTypeOptions: this.$cx.mapEnumToOptions(CxNetworkType),
      networkProtocolOptions: this.$cx.mapEnumToOptions(CxNetworkProtocol),
      isEditDialogVisible: false,
      selectedSubnet: {} as IPPoolSubnetDto,
      setGateways: false,
      subnetHostCount: 0,
    }
  },
  mounted() {
    this.load();
  },
  watch: {
    id: function () {
      this.load();
    },
    isEditDialogVisible(newValue) {
      if (!newValue) {
        this.onSubnetPageChanged({
          rows: 15,
          page: 0
        });
      }
    },
    'entity.subnetPrefix'() {
      this.calculateMaxSubnetHostCount();
    }
  },
  computed: {
    isEditing() {
      return this.id != null;
    },
    networkPrefixOptions() {
      if (this.entity.networkProtocol == CxNetworkProtocol.IPV4)
        return ipv4NetworkMaskOptions.map((p: any) => ({label: p.name, value: p.value}))
      else
        return ipv6NetworkMaskOptions.map((p: any) => ({label: p.name, value: p.value}))
    },
    customSubnetPrefixOptions() {
      if (this.entity.networkPrefix == null) return this.networkPrefixOptions;
      if (this.entity.networkProtocol == CxNetworkProtocol.IPV4)
        return ipv4NetworkMaskOptions
            .filter(p => p.value >= this.entity.networkPrefix!)
            .map((p: any) => ({label: p.name, value: p.value}))
      else
        return ipv6NetworkMaskOptions
            .filter(p => p.value >= this.entity.networkPrefix!)
            .map((p: any) => ({label: p.name, value: p.value}))
    },
    subnetPrefixOptions() {
      if (this.entity.networkPrefix == null) return this.networkPrefixOptions;
      if (this.entity.networkProtocol == CxNetworkProtocol.IPV4)
        return ipv4NetworkMaskOptions
            .filter(p => p.value >= this.entity.networkPrefix!)
            .map((p: any) => ({label: p.name, value: p.value - this.entity.networkPrefix!}))
      else
        return ipv6NetworkMaskOptions
            .filter(p => p.value >= this.entity.networkPrefix!)
            .map((p: any) => ({label: p.name, value: p.value - this.entity.networkPrefix!}))
    },
    subnetBits() {
      if (this.entity.networkPrefix == null) return 0
      if (this.entity.subnetPrefix == null) return 0
      return this.entity.networkPrefix + this.entity.subnetPrefix
    },
    subnetTableConfig() {
      let tC = new CxTableConfig([
        new CxTableColumn("subnetIp", this.$t('ipPool', 'subnetIp')),
        new CxTableColumn("range", "", CxDataType.Custom),
        new CxTableColumn("alloc", "", CxDataType.Custom),
        new CxTableColumn("subnetPrefix", "", CxDataType.Custom),
        new CxTableColumn("subnetOccupancyRate", "", CxDataType.Custom),
        new CxTableColumn("vlans", this.$t("vlan", 'lb_pl'), CxDataType.GetNameFromArray),
        new CxTableColumn("isAssigned", this.$t('ipPool', 'networkAssigned'), CxDataType.Checkbox),
      ]);
      tC.totalRecords = this.subnetTotalCount
      tC.lazy = true
      return tC
    },
    vlanTableConfig() {
      let tc = new CxTableConfig([
        new CxTableColumn("vlan", this.$t("vlan", 'lb'), CxDataType.Custom),
        new CxTableColumn("virtualNetwork", this.$t("virtualNetwork", 'lb'), CxDataType.Custom),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
      return tc;
    },
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    this.$cx.notifyUnsavedChanges(next, this.initialState, this.entity);
  },
  methods: {
    load() {
      if (this.isEditing) {
        this.$store
            .dispatch("ipPool/getById", this.id)
            .then((entity) => {
              console.log(entity)
              this.entity = entity;
              this.initialState = this.$cx.getState(this.entity);
              this.onSubnetPageChanged({
                rows: 15,
                page: 0
              })

              if(entity.vlans != null && entity.vlans.length != 0) {
                this.virtualNetwork = entity.vlans[0].virtualNetwork
                this.loadOptions(this.virtualNetwork)
                this.virtualNetworkVlans = entity.vlans
                this.vlansForSubnet = entity.vlans
              }
            })
            .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
        this.loadVlans();
      } else {
        this.entity.networkProtocol = CxNetworkProtocol.IPV4
        this.entity.gatewayIpPosition = 1;
      }
    },
    async save(goBack: Boolean) {
      if (await this.$cx.notifyValidationError(this.v$)) return;

      this.entity.vlans = this.virtualNetworkVlans;

      if (this.isEditing) {
        this.$store
            .dispatch("ipPool/save", this.entity)
            .then(() => {
              this.$cx.notifySaved(this.$t("ipPool", "lb"));
              this.initialState = this.$cx.getState(this.entity);
              if (goBack)
                this.$cx.goTo("ipPool")
            })
            .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      } else {
        let ipPoolType = (this.isVLSM ? CxIPPoolType.VLSM : CxIPPoolType.PREALLOCATED)
        this.entity.vlans = this.virtualNetworkVlans
        this.$store
            .dispatch("ipPool/create", {entity: this.entity, type: ipPoolType})
            .then((newEntity: IPPoolDto) => {
              this.$cx.notifyCreated(this.$t("ipPool", "lb"));
              this.entity = newEntity;
              this.initialState = this.$cx.getState(this.entity);
              if (goBack)
                this.$cx.goTo("ipPool")
              else
                this.$cx.goToById("ipPoolEditor", newEntity.id!);
            })
            .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
      }
    },
    onNetworkPrefixChanged() {
      this.entity.subnetPrefix = undefined
    },
    async onUpdateNetworkInfo() {
      if (this.v$.$invalid) return
      this.$store
          .dispatch("ipPool/refresh", this.entity)
          .then((ipPoolData: IPPoolDto) => {
            this.entity = ipPoolData
          })
          .catch((error) => this.$cx.error(error, "Error updating network information"));
    },
    onCreateSubnet() {
      if (this.subnet.prefix == null || this.subnet.count == null || this.subnet.count < 1) {
        this.$cx.warn(this.$cx.e("validating"), this.$c("subnet"));
        return
      }

      this.subnet.vlans = this.vlansForSubnet

      this.$store
          .dispatch("ipPool/createSubnet", {id: this.id, entity: this.subnet})
          .then(() => {
            this.$cx.success(this.$t('ipPool', "subnetCreated"), this.$t('ipPool', "subnetCreated"))
            this.onSubnetPageChanged({
              rows: 15,
              page: 0
            })
          })
          .catch((error) => this.$cx.error(error, "Error creating subnet"));
    },
    onEditSubnet(data: any) {
      this.selectedSubnet = {...data};
      this.isEditDialogVisible = true;
    },
    onDeleteSubnet(data: any) {
      this.$cx.confirmDeleteDetail(
          data.subnetIp,
          () => {
            this.$store
                .dispatch("ipPool/deleteSubnet", {poolId: this.id, subnetId: data.id})
                .then(() => {
                  this.$cx.notifyDeleted(this.$t("subnet", "lb"))
                  this.onSubnetPageChanged({
                    page: 0,
                    rows: 15
                  })
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("deleting")));
          })
    },
    onSubnetPageChanged(ev: any) {
      this.$store.dispatch("ipPool/getSubnet", {
        id: this.id,
        page: ev.page,
        size: ev.rows
      }).then(d => {
        this.subnets = d.content
        this.subnetTotalCount = d.totalPages * 15
      })
    },

    loadOptions(vrf: any) {
      this.$store
      .dispatch("virtualNetwork/getVlans", vrf.id)
      .then((vlanOptions) => {
        this.vlanOptions = vlanOptions
      });
    },
    // VirtualNetworks, Vlans

    onVRFChanged(ev: any) {
      this.virtualNetworkVlans = [] as VlanDto[]
      this.vlansForSubnet = [] as VlanDto[]
      this.vlanOptions = [] as VlanDto[]
      if (ev.value == null) return
      this.$store
          .dispatch("virtualNetwork/getVlans", ev.value.id)
          .then((vlanOptions) => {
            this.vlanOptions = []
            vlanOptions.forEach((v: VlanDto) => {
              if (this.virtualNetworkVlans.find(vn => vn.id == v.id && vn.virtualNetwork!.id == ev.value.id) == null)
                this.vlanOptions.push(v)
            })
          });
    },
    onCreateVlan() {
      /*if (this.vlan.virtualNetwork == null || this.vlan.vlan == null) {
        this.$cx.warn(this.$t("errorValidating"), this.$t("vlan"));
        return
      }

      this.vlan.ipPool = Object.assign({}, this.entity)

      this.$store
        .dispatch("ipPool/createVlan", { id: this.id, entity: this.vlan })
        .then(() => {
          this.$cx.successNotify(this.$t("VLANCreated"), this.$t("VLANCreated"), 1000)
          this.vlan = {}
          this.loadVlans()
        })
        .catch((error) => this.$cx.error(error, this.$t("VLANCreatingError"));*/
    },

    loadVlans() {
      this.$store
          .dispatch("ipPool/getAllVlans", this.id)
          .then((vlans) => {
            this.vlans = vlans
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },

    onDeleteVlan(vlan: any) {
      this.$cx.confirmDeleteDetail(
          vlan.vlan.name,
          () => {
            this.$store
                .dispatch("ipPool/deleteVlan", {poolId: this.id, virtualNetworkId: vlan.virtualNetwork.id})
                .then(() => {
                  this.$cx.notifyDeleted(this.$t("vlan", "lb"))
                  this.loadVlans()
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("deleting")));
          })
    },
    getOccupancyRate(data: any) {
      const n = 32 - data.subnetPrefix;
      let usableAddresses = 0;
      if (n > 1) {
        usableAddresses = 2 ** n - 2;
      } else {
        usableAddresses = 2 ** n;
      }
      const usedAddresses = data.usedAddresses.length;
      return `${usedAddresses}/${usableAddresses}`;
    },
    calculateMaxSubnetHostCount() {
      if(this.entity.networkPrefix != null && this.entity.subnetPrefix != null && this.entity.gatewayIpPosition != null) {
        this.subnetHostCount = 2 ** (32 - (this.entity.networkPrefix + this.entity.subnetPrefix));
        if (this.entity.gatewayIpPosition > this.subnetHostCount) {
          this.entity.gatewayIpPosition = this.subnetHostCount;
        }
      }
    },
    resetGatewayPosition() {
      if (!this.setGateways) {
        this.entity.gatewayIpPosition = 0;
      } else {
        this.entity.gatewayIpPosition = 1;
      }
    }
  }
});
