import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cx-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_cx_import_export_button = _resolveComponent("cx-import-export-button")!
  const _component_cx_create_button = _resolveComponent("cx-create-button")!
  const _component_cx_edit_button = _resolveComponent("cx-edit-button")!
  const _component_cx_delete_button = _resolveComponent("cx-delete-button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_cx_table_filter = _resolveComponent("cx-table-filter")!
  const _component_cx_page = _resolveComponent("cx-page")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    title: _ctx.$t('deviceModel', 'lb')
  }, {
    actions: _withCtx(() => [
      _withDirectives(_createVNode(_component_Button, {
        icon: "pi pi-book",
        onClick: _ctx.getEoxRecords,
        disabled: !_ctx.$auth.permits(_ctx.$perm.DEVICE_MODEL, _ctx.$lvl.READ),
        label: _ctx.$t('eoxRecord', 'lb_pl')
      }, null, 8, ["onClick", "disabled", "label"]), [
        [
          _directive_tooltip,
          _ctx.$t('eoxRecord', 'init'),
          void 0,
          { bottom: true }
        ]
      ]),
      _withDirectives(_createVNode(_component_Button, {
        icon: "pi pi-megaphone",
        onClick: _ctx.getAllBugs,
        disabled: !_ctx.$auth.permits(_ctx.$perm.DEVICE_MODEL, _ctx.$lvl.READ),
        label: 'Bugs'
      }, null, 8, ["onClick", "disabled"]), [
        [
          _directive_tooltip,
          _ctx.$t('ciscoBug', 'button_tooltip_pl'),
          void 0,
          { bottom: true }
        ]
      ]),
      _createVNode(_component_cx_import_export_button, {
        store: "deviceModel",
        importTableConfig: _ctx.importTableConfig
      }, null, 8, ["importTableConfig"]),
      _createVNode(_component_cx_create_button, {
        route: "deviceModelEditorNew",
        grant: {
        perm: _ctx.$perm.DEVICE_MODEL,
        lvl: _ctx.$lvl.WRITE
      }
      }, null, 8, ["grant"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_cx_table_filter, {
        stateKey: "deviceModel",
        data: _ctx.deviceModels,
        config: _ctx.tableConfig,
        ref: "tbl",
        loading: _ctx.$store.state.deviceModel.loading,
        onFilter: _ctx.load,
        tableStyle: "height: calc(100vh - 78px)"
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_Column, {
            header: _ctx.$c('actions'),
            frozen: "",
            alignFrozen: "right",
            headerStyle: "width:110px"
          }, {
            filter: _withCtx(() => [
              _createVNode(_component_Button, {
                icon: "pi pi-filter-slash",
                class: "align-self-end p-button-text",
                onClick: _ctx.onClearFilters
              }, null, 8, ["onClick"])
            ]),
            body: _withCtx((props) => [
              _createElementVNode("div", _hoisted_1, [
                _withDirectives(_createVNode(_component_Button, {
                  icon: "pi pi-megaphone",
                  onClick: ($event: any) => (_ctx.getBugsByModel(props.data)),
                  disabled: !_ctx.$auth.permits(_ctx.$perm.DEVICE_MODEL, _ctx.$lvl.READ)
                }, null, 8, ["onClick", "disabled"]), [
                  [
                    _directive_tooltip,
                    _ctx.$t('ciscoBug', 'button_tooltip'),
                    void 0,
                    { bottom: true }
                  ]
                ]),
                _createVNode(_component_cx_edit_button, {
                  route: "deviceModelEditor",
                  id: props.data.id,
                  grant: {
                perm: _ctx.$perm.DEVICE_MODEL,
                lvl: _ctx.$lvl.WRITE
              }
                }, null, 8, ["id", "grant"]),
                _createVNode(_component_cx_delete_button, {
                  onClick: ($event: any) => (_ctx.onDelete(props.data)),
                  grant: {
                perm: _ctx.$perm.DEVICE_MODEL,
                lvl: _ctx.$lvl.WRITE
              }
                }, null, 8, ["onClick", "grant"])
              ])
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["data", "config", "loading", "onFilter"])
    ]),
    _: 1
  }, 8, ["title"]))
}