import { api } from "@/_config/api";

const entityName = "dashboard";

export default {
    namespaced: true,
    state: {
        loading: false
    },
    actions: {
        async getStats(context: any) {
            context.state.loading = true;
            try {
                let response = await api.get(`${entityName}/stats`);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async getChartData(context: any) {
            context.state.loading = true;
            try {
                let response = await api.get(`${entityName}/charts`);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        getSoftwareVersions: async (context: any) => {
            context.state.loading = true;
            try {
                let response = await api.get(`/dashboard/softwareVersions`);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        getSoftwareDashboardData: async (context: any, filters: any) => {
            context.state.loading = true;
            try {
                let response = await api.get(`${entityName}/softwareManagement/data`, {
                    params: filters,
                    paramsSerializer: { indexes: null }
                },
                );
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        getSoftwareComplianceData: async (context: any) => {
            context.state.loading = true;
            try {
                let response = await api.get(`${entityName}/softwareCompliance/data`);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        getConfigurationComplianceData: async (context: any) => {
            context.state.loading = true;
            try {
                let response = await api.get(`${entityName}/configurationCompliances`);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
    }
};


