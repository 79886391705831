
import {
    ConfigurationDto,
    CxConfigSource,
} from "@/types/dto";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        id: String,
        isDiff: {
            type: Boolean,
            default: true
        },
        hasThirdColumn: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            sourceConfiguration: { text: '' } as any,
            targetConfiguration: { text: '' } as any,
            configurations: [] as ConfigurationDto[],
            configFilter: [CxConfigSource.GENERATED, CxConfigSource.RUNNING_CONFIG] as any[],
            configFilterOptions: this.$cx.mapEnumToOptions(CxConfigSource),
            selectedDevice: {} as any,
        };
    },
    mounted() {
        this.loadConfigs(this.id)
    },
    watch: {

    },
    computed: {
        filteredConfigurations() {
            return this.configurations.filter(c => {
                return this.configFilter.includes(c.source)
            })
        }
    },

    methods: {
        loadConfigs(id: any) {
            if ((id || this.id) == null) return
            this.$store
                .dispatch("locationDevice/configs", (id || this.id))
                .then((configs: ConfigurationDto[]) => {
                    this.sourceConfiguration = { text: '' };
                    this.targetConfiguration = { text: '' };
                    this.configurations.length = 0;
                    this.configurations = configs;
                    this.configurations.forEach((c) => {
                        c.version = "V" + c.version;
                    });

                    if (this.configurations.length > 0) {
                        this.sourceConfiguration = this.configurations[0]
                        this.targetConfiguration = this.configurations[0]
                        if (this.configurations.length > 1)
                            this.sourceConfiguration = this.configurations[1]
                    }

                    this.$emit('onDeviceChanged', {
                        device: this.selectedDevice,
                        source: this.sourceConfiguration,
                        target: this.targetConfiguration
                    })
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
        },
        onConfigurationSelected(ev: any, side: string) {
            if (side == 'left') {
                this.sourceConfiguration = ev.value
            } else if (side == 'right') {
                this.targetConfiguration = ev.value
            }
            this.$emit('onDeviceChanged', {
                device: this.selectedDevice,
                source: this.sourceConfiguration,
                target: this.targetConfiguration
            })
        },
        isRunningConfig(config: ConfigurationDto) {
            return config.source == CxConfigSource.RUNNING_CONFIG
        },
        onOpenConfigWizard(config: ConfigurationDto) {
            this.$cx.goToNewTabWithArgs('configurationWizard', {
                locationDeviceId: this.id,
                configId: config.id
            })
        },
        onDeviceChanged(ev: any) {
            if (this.selectedDevice != null && this.selectedDevice.id != null) {
                this.loadConfigs(this.selectedDevice.id)
            } else if (this.id != null) {
                this.loadConfigs(this.id)
            }
        },
    },
});
