import CxBaseStoreModule from "@/types/cx-store-module";
import {ApiParamDto, CxEntityType} from "@/types/dto";
import {api} from "@/_config/api";

class PoolPortMappingStore extends CxBaseStoreModule<ApiParamDto> {

    constructor() {
        super("poolPortMapping")
        this.state.entityType = CxEntityType.POOL_PORT_MAPPING
    }

    defineState(state: any) {
        return super.defineState({
            testConnectionLoading: false
        })
    }

    defineActions(actions: any) {
        return super.defineActions({
            assignManagementIPsForLocationById: async (context: any, id: any) => {
                context.state.loading = true;
                try {
                    let response = await api.post(`${context.state.entityName}/assign/location/${id}`);
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
            assignManagementIPsForAllLocations: async (context: any) => {
                context.state.loading = true;
                try {
                    let response = await api.post(`${context.state.entityName}/assign/location`);
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
        })
    }
}

export default new PoolPortMappingStore().createStore()