
import {
  CreateLocationDeviceRequest,
  CxEntityType,
  LocationDeviceDto,
  PoolPortMappingDto
} from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { CxFormApi } from "@/types/cx-form-api";
import { defineComponent, PropType } from "vue";
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";
import { ApprovalState } from "@/types/approval-state";
import CxDropdown from "@/components/CxDropdown.vue";
import CxLabel from "@/components/CxLabel.vue";

export default defineComponent({
  components: { CxLabel, CxDropdown },
  props: {
    editorApi: {
      type: Object as PropType<CxFormApi>,
    },
    locationId: String,
    approvalState: {
      type: Object as PropType<ApprovalState>,
      default: new ApprovalState(CxEntityType.GLOBAL)
    }
  },
  data() {
    return {
      id: -1 as number,
      entity: {} as LocationDeviceDto,
      initialEntity: {} as LocationDeviceDto,
      serials: "",
      inBandManagement: {} as PoolPortMappingDto,
      isLogicalIn: false as boolean,
      isPoolGroupIn: false as boolean,
      outOfBandManagement: {} as PoolPortMappingDto,
      isLogicalOut: false as boolean,
      isPoolGroupOut: false as boolean,
    };
  },
  setup: () => ({ v$: useVuelidate() as any }),
  validations() {
    let v = {
      entity: {
        name: { required, maxLength: maxLength(vMaxLen.name), $autoDirty: true },
        deviceRole: { required, $autoDirty: true },
        deviceTemplate: { required, $autoDirty: true }
      },
      inBandManagement: {
        physicalPort: {},
        logicalInterface: {}
      },
      outOfBandManagement: {
        physicalPort: {},
        logicalInterface: {}
      }
    }
    if (!this.isEditing) {
      v = {
        ...v, ...{
          serials: {
            required
          }
        }
      }
    }
    return v
  },
  watch: {
    entity: {
      handler(updatedEntity) {
        this.entity = updatedEntity;
      },
      deep: true
    }
  },
  computed: {
    isEditing() {
      return this.id > 0;
    },
  },
  created() {
    //set default when creating new device
    if (!this.isEditing) {
      this.outOfBandManagement.isPrimary = true;
      this.inBandManagement.isPrimary = false;
    }
    this.editorApi!.onSave = async (closeFn: any) => {
      if (await this.$cx.notifyValidationError(this.v$)) return false;
      if (this.failsGeneralValidationsForManagements() || this.failsPrimaryValidationsForManagements()) {
        return false;
      }

      if (this.isEditing) {
        this.fillEntity();
        this.$store
          .dispatch("locationDevice/save", this.entity)
          .then(() => {
            this.$cx.notifySaved(this.$t("locationDevice", "lb"));
            this.initialEntity = this.$cx.getState(this.entity);
            closeFn();
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      } else {
        this.fillEntity();
        this.entity.location = { id: parseInt(this.locationId!) };
        let createEntity = Object.assign({}, this.entity) as CreateLocationDeviceRequest
        createEntity.serials = this.serials.replaceAll(/ /g, "").split(/[\n,]+/).filter(f => f.trim().length > 0)
        this.$store
          .dispatch("locationDevice/create", createEntity)
          .then((newEntity) => {
            this.$cx.notifyCreated(this.$t("locationDevice", "lb"));
            this.initialEntity = this.$cx.getState(this.entity);
            closeFn(newEntity);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
      }
    };
    this.editorApi!.load = (id: number) => {
      this.id = id;
      this.$store.dispatch("locationDevice/getById", id).then((entity) => {
        this.entity = entity;
        this.initialEntity = this.$cx.getState(this.entity);
        this.$emit("entity", entity, this.initialEntity);
        this.loadPortMappings();
      });
    };
    this.editorApi!.delete = (id: number) => {
      this.$store.dispatch("locationDevice/delete", id).then(() => {
        this.$cx.notifyDeleted(this.$t("locationDevice", "lb"));
      });
    };
    this.editorApi!.notifyUnsavedChanges = (next: any) => {
      this.$cx.notifyUnsavedChanges(next, this.initialEntity, this.entity);
    }
    this.editorApi!.reset = () => {
      this.entity = {} as LocationDeviceDto;
    };
    this.editorApi!.isEditing = () => {
      return this.id > 0;
    };
  },
  methods: {
    getModels(entity: any) {
      if (entity.deviceTemplateModels == null) return ''
      if (entity.deviceTemplateModels.length < 1) return ''
      return entity.deviceTemplateModels.map((dtm: any) => dtm.deviceModel)
    },
    fillEntity() {
      if (this.inBandManagement.ipPool && !this.isPoolGroupIn) {
        this.inBandManagement.ipPoolGroup = null;
        this.setInBandPorts();
      } else if(this.inBandManagement.ipPoolGroup && this.isPoolGroupIn) {
        this.inBandManagement.ipPool = null;
        this.setInBandPorts();
      }
      if (this.outOfBandManagement.ipPool && !this.isPoolGroupOut) {
        this.outOfBandManagement.ipPoolGroup = null;
        this.setOutBandPorts();
      } else if(this.outOfBandManagement.ipPoolGroup && this.isPoolGroupOut) {
        this.outOfBandManagement.ipPool = null;
        this.setOutBandPorts();
      }
    },
    setInBandPorts() {
      if (this.isLogicalIn) {
        this.inBandManagement.physicalPort = null as any;
      } else {
        this.inBandManagement.logicalInterface = null as any;
      }
      this.inBandManagement.type = "MANAGEMENT_IN_BAND" as any;
      if (this.entity.poolPortMappings) {
        const existingMappingIndex = this.entity.poolPortMappings.findIndex(mapping => mapping.type === "MANAGEMENT_IN_BAND");
        if(existingMappingIndex > -1) {
          this.entity.poolPortMappings[existingMappingIndex] = this.inBandManagement;
        } else {
          this.entity.poolPortMappings.push(this.inBandManagement);
        }
      } else {
        this.entity.poolPortMappings = [this.inBandManagement];
      }
    },
    setOutBandPorts() {
      if (this.isLogicalOut) {
        this.outOfBandManagement.physicalPort = null as any;
      } else {
        this.outOfBandManagement.logicalInterface = null as any;
      }
      this.outOfBandManagement.type = "MANAGEMENT_OUT_OF_BAND" as any;
      if (this.entity.poolPortMappings) {
        const existingMappingIndex = this.entity.poolPortMappings.findIndex(mapping => mapping.type === "MANAGEMENT_OUT_OF_BAND");
        if(existingMappingIndex > -1) {
          this.entity.poolPortMappings[existingMappingIndex] = this.outOfBandManagement;
        } else {
          this.entity.poolPortMappings.push(this.outOfBandManagement);
        }
      } else {
        this.entity.poolPortMappings = [this.outOfBandManagement];
      }
    },
    loadPortMappings() {
      if (this.entity.poolPortMappings && this.entity.poolPortMappings.length > 0) {
        this.entity.poolPortMappings.forEach(mapping => {
          if (mapping.type === 'MANAGEMENT_IN_BAND') {
            this.inBandManagement = mapping;
            this.isLogicalIn = mapping.logicalInterface != null;
            this.isPoolGroupIn = mapping.ipPoolGroup != null;
            if(mapping.isPrimary) {
              this.outOfBandManagement.isPrimary = false;
            }
          } else if (mapping.type === 'MANAGEMENT_OUT_OF_BAND') {
            this.outOfBandManagement = mapping;
            this.isLogicalOut = mapping.logicalInterface != null;
            this.isPoolGroupOut = mapping.ipPoolGroup != null;
            if(mapping.isPrimary) {
              this.inBandManagement.isPrimary = false;
            }
          }
        });
      }
    },
    onOutPrimaryChanged() {
      this.inBandManagement.isPrimary = this.outOfBandManagement.isPrimary;
    },
    onInPrimaryChanged() {
      this.outOfBandManagement.isPrimary = this.inBandManagement.isPrimary;
    },
    failsPrimaryValidationsForManagements() {
      const { logicalInterface: inLogical, physicalPort: inPhysical, ipPool: inIpPool, ipPoolGroup: inGroup } = this.inBandManagement;
      const { logicalInterface: outLogical, physicalPort: outPhysical, ipPool: outIpPool, ipPoolGroup: outGroup } = this.outOfBandManagement;

      if(this.inBandManagement.isPrimary && this.outOfBandManagement.isPrimary) {
        this.$cx.notifyGenericError(this.$t("locationDevice", "vErrorTitle"), this.$t("locationDevice", "vError11"));
        return true;
      }
      if(!this.inBandManagement.isPrimary && !this.outOfBandManagement.isPrimary) {
        this.$cx.notifyGenericError(this.$t("locationDevice", "vErrorTitle"), this.$t("locationDevice", "vError12"));
        return true;
      }
      if(this.inBandManagement.isPrimary && !inLogical && !inPhysical && !inIpPool && !inGroup) {
        if(!(!outLogical && !outPhysical && !outIpPool && !outGroup)) {
          this.$cx.notifyGenericError(this.$t("locationDevice", "vErrorTitle"), this.$t("locationDevice", "vError13"));
          return true;
        }
      }
      if(this.outOfBandManagement.isPrimary && !outLogical && !outPhysical && !outIpPool && !outGroup) {
        if(!(!inLogical && !inPhysical && !inIpPool && !inGroup)) {
          this.$cx.notifyGenericError(this.$t("locationDevice", "vErrorTitle"), this.$t("locationDevice", "vError13"));
          return true;
        }
      }
      return false;
    },
    failsGeneralValidationsForManagements() {
      const {logicalInterface: inLogical, physicalPort: inPhysical, ipPool: inIpPool, ipPoolGroup: inGroup} = this.inBandManagement;
      const {logicalInterface: outLogical, physicalPort: outPhysical, ipPool: outIpPool, ipPoolGroup: outGroup} = this.outOfBandManagement;

      if (this.isNotUniqueInterface(inLogical, outLogical)) {
        this.$cx.notifyGenericError(this.$t("locationDevice", "vErrorTitle"), this.$t("locationDevice", "vError1"));
        return true;
      }
      if (this.isNotUniquePort(inPhysical, outPhysical)) {
        this.$cx.notifyGenericError(this.$t("locationDevice", "vErrorTitle"), this.$t("locationDevice", "vError2"));
        return true;
      }
      if (this.hasNoInPhysical(inPhysical)) {
        this.$cx.notifyGenericError(this.$t("locationDevice", "vErrorTitle"), this.$t("locationDevice", "vError3"));
        return true;
      }
      if (this.hasNoInLogical(inLogical)) {
        this.$cx.notifyGenericError(this.$t("locationDevice", "vErrorTitle"), this.$t("locationDevice", "vError4"));
        return true;
      }
      if (this.hasNoOutPhysical(outPhysical)) {
        this.$cx.notifyGenericError(this.$t("locationDevice", "vErrorTitle"), this.$t("locationDevice", "vError5"));
        return true;
      }
      if (this.hasNoOutLogical(outLogical)) {
        this.$cx.notifyGenericError(this.$t("locationDevice", "vErrorTitle"), this.$t("locationDevice", "vError6"));
        return true;
      }
      if (this.hasNoInIpPool(inIpPool)) {
        this.$cx.notifyGenericError(this.$t("locationDevice", "vErrorTitle"), this.$t("locationDevice", "vError7"));
        return true;
      }
      if (this.hasNoOutIpPool(outIpPool)) {
        this.$cx.notifyGenericError(this.$t("locationDevice", "vErrorTitle"), this.$t("locationDevice", "vError8"));
        return true;
      }
      if (this.hasNoInIpPoolGroup(inGroup)) {
        this.$cx.notifyGenericError(this.$t("locationDevice", "vErrorTitle"), this.$t("locationDevice", "vError9"));
        return true;
      }
      if (this.hasNoOutIpPoolGroup(outGroup)) {
        this.$cx.notifyGenericError(this.$t("locationDevice", "vErrorTitle"), this.$t("locationDevice", "vError10"));
        return true;
      }
      return false;
    },
    isNotUniqueInterface(inLogical: any, outLogical: any) {
      return inLogical && outLogical && inLogical.id === outLogical.id && this.isLogicalIn && this.isLogicalOut
    },
    isNotUniquePort(inPhysical: any, outPhysical: any) {
      return inPhysical && outPhysical && inPhysical.id === outPhysical.id && !this.isLogicalIn && !this.isLogicalOut
    },
    hasNoInPhysical(inPhysical: any) {
      return !inPhysical && !this.isLogicalIn && this.hasInIpPoolOrGroup();
    },
    hasNoInLogical(inLogical: any) {
      return this.isLogicalIn && !inLogical && this.hasInIpPoolOrGroup();
    },
    hasNoOutPhysical(outPhysical: any) {
      return !outPhysical && !this.isLogicalOut && this.hasOutIpPoolOrGroup();
    },
    hasNoOutLogical(outLogical: any) {
      return this.isLogicalOut && !outLogical && this.hasOutIpPoolOrGroup();
    },
    hasNoInIpPool(inIpPool: any) {
      return this.hasInPort() && !inIpPool && !this.isPoolGroupIn;
    },
    hasNoOutIpPool(outIpPool: any) {
      return this.hasOutPort() && !outIpPool && !this.isPoolGroupOut;
    },
    hasNoInIpPoolGroup(inIpPoolGroup: any) {
      return this.hasInPort() && !inIpPoolGroup && this.isPoolGroupIn;
    },
    hasNoOutIpPoolGroup(outIpPoolGroup: any) {
      return this.hasOutPort() && !outIpPoolGroup && this.isPoolGroupOut;
    },
    hasInIpPoolOrGroup() {
      return (this.inBandManagement.ipPool && !this.isPoolGroupIn) || (this.inBandManagement.ipPoolGroup && this.isPoolGroupIn);
    },
    hasInPort() {
      return ((!this.isLogicalIn && this.inBandManagement.physicalPort) || (this.isLogicalIn && this.inBandManagement.logicalInterface))
    },
    hasOutIpPoolOrGroup() {
      return (this.outOfBandManagement.ipPool && !this.isPoolGroupOut) || (this.outOfBandManagement.ipPoolGroup && this.isPoolGroupOut);
    },
    hasOutPort() {
      return ((!this.isLogicalOut && this.outOfBandManagement.physicalPort) || (this.isLogicalOut && this.outOfBandManagement.logicalInterface))
    }
  }
});
