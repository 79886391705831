import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "scd-charts" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData, (chart, c) => {
        return (_openBlock(), _createBlock(_component_Chart, {
          key: c,
          type: "bar",
          data: chart,
          options: _ctx.barChartOptions,
          width: 300,
          height: 200,
          plugins: [_ctx.chartPlugin]
        }, null, 8, ["data", "options", "plugins"]))
      }), 128))
    ]),
    _createVNode(_component_DataTable, {
      class: "smgmt-table",
      value: _ctx.tableData,
      filters: _ctx.tableFilters,
      "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tableFilters) = $event)),
      filterDisplay: "row",
      showGridlines: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.softwareComplianceTableConfig.columns, (col, c) => {
          return (_openBlock(), _createBlock(_component_Column, {
            key: c,
            field: col.field,
            header: col.label,
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data[col.field]), 1)
            ]),
            filter: _withCtx(({ filterModel, filterCallback }) => [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: filterModel.value,
                "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                onInput: ($event: any) => (filterCallback()),
                class: "p-column-filter",
                placeholder: _ctx.$c('searchText')
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "placeholder"])
            ]),
            _: 2
          }, 1032, ["field", "header"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "filters"])
  ], 64))
}