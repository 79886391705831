import { api } from "@/_config/api";
import { ConfigurationDto, ConfigurationType, CxEntityType } from "@/types/dto";
import CxBaseStoreModule from "@/types/cx-store-module";

class ConfigurationStore extends CxBaseStoreModule<ConfigurationDto> {

    constructor() {
        super("configuration")
        this.state.entityType = CxEntityType.CONFIGURATION
    }

    defineState(state: any) {
        return super.defineState({

        })
    }

    defineActions(actions: any) {
        return super.defineActions({
            getAllByType: async (context: any, type: ConfigurationType) => {
                context.state.loading = true;
                try {
                    let response = await api.get(context.state.entityName + "/type/" + type);
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
            getVariables: async (context: any) => {
                try {
                    let response = await api.get(context.state.entityName + "/variables");
                    return response.data;
                } catch (error) {
                    console.error(error);
                    return Promise.reject(error);
                }
            },
            compile: async (context: any, text: string) => {
                try {
                    let response = await api.post(`${context.state.entityName}/compile`, { text: text });
                    return response.data;
                } catch (error) {
                    console.error(error);
                    return Promise.reject(error);
                }
            },
            getDayNEditorData: async (context: any, ids: number[]) => {
                try {
                    let response = await api.get(`${context.state.entityName}/dayn/editor?locationDeviceIds=${ids.join(',')}`);
                    return response.data;
                } catch (error) {
                    console.error(error);
                    return Promise.reject(error);
                }
            },
            saveDayNEditorData: async (context: any, data: any) => {
                try {
                    let response = await api.post(`${context.state.entityName}/dayn/editor/save`, data);
                    return response.data;
                } catch (error) {
                    console.error(error);
                    return Promise.reject(error);
                }
            },
            getDayNChanges: async (context: any, data: any) => {
                try {
                    let response = await api.put(`${context.state.entityName}/dayn/editor/changes`, data);
                    return response.data;
                } catch (error) {
                    console.error(error);
                    return Promise.reject(error);
                }
            }
        })
    }
}

export default new ConfigurationStore().createStore()