export function buildChartDataSet($t: any) {
    return {
        labels: [] as any[],
        datasets: [
            {
                label: $t('softwareCompliance', 'isValid'),
                backgroundColor: '#8FD400',
                borderColor: 'black',
                data: [] as any[]
            },
            {
                label: $t('softwareCompliance', 'inValid'),
                backgroundColor: '#fab710',
                borderColor: 'black',
                data: [] as any[]
            },
            {
                label: $t('softwareCompliance', 'unknown'),
                backgroundColor: '#bbbbbb',
                borderColor: 'black',
                data: [] as any[]
            }
        ],
    }
}