
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";
import {
  LocationDeviceDto,
  LocationDto,
  CxConfigResult,
  CxLocationConfigResult,
  ScheduleDto,
  LocationStateDto,
  CiscoPnpRequestDto,
  LocationSubnetDto,
  LocationDeviceViewDto
} from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { defineComponent } from "vue";
import {
  CxTableConfig,
  CxTableColumn,
  CxDataType,
} from "@/types/cx-table-config";
import { CxFormApi } from "@/types/cx-form-api";
import Button from "primevue/button";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Listbox from "primevue/listbox";
import { Diff } from "vue-diff";

export default defineComponent({
  props: {
    id: String,
  },
  setup: () => ({ v$: useVuelidate() as any }),
  validations() {
    return {
      entity: {
        name: {
          required,
          maxLength: maxLength(vMaxLen.name),
          $autoDirty: true,
        },
        postCode: {
          required,
          maxLength: maxLength(vMaxLen.postCode),
          $autoDirty: true,
        },
        street: {
          required,
          maxLength: maxLength(vMaxLen.text),
          $autoDirty: true,
        },
        streetNumber: {
          required,
          maxLength: maxLength(vMaxLen.streetNumber),
          $autoDirty: true,
        },
        continent: {
          required,
          maxLength: maxLength(vMaxLen.text),
          $autoDirty: true,
        },
        city: {
          required,
          maxLength: maxLength(vMaxLen.text),
          $autoDirty: true,
        },
        country: {
          required,
          maxLength: maxLength(vMaxLen.text),
          $autoDirty: true,
        },
        state: { maxLength: maxLength(vMaxLen.text), $autoDirty: true },
        locationTypes: { required, $autoDirty: true },
        locationCategory: { required, $autoDirty: true },
      },
    };
  },
  data() {
    return {
      entity: {} as LocationDto,
      initialState: {} as LocationDto,
      locationDeviceData: [] as LocationDeviceViewDto[],
      locationDeviceInteval: null as any,
      isAddDeviceDialogVisible: false,
      newDevice: {} as LocationDeviceDto,
      deviceApi: {} as CxFormApi,
      pnpRequestData: [] as CiscoPnpRequestDto[],
      isConfigCompareDialogVisible: false,
      configCompareResult: [] as any[],
      selectedCompareConfigs: [] as any[],
      selectedCompareDevice: {} as any[],
      sourceConfig: {} as any,
      targetConfig: {} as any,
      schedules: [] as ScheduleDto[],
      schedulerInterval: null as any,
      states: [] as LocationStateDto[],
      subnets: {} as { [key: string]: LocationSubnetDto[] },
      locationDeviceTableRef: "",
      isGeneratingConfigByDeviceIdMap: {} as { [key: number]: boolean }
    };
  },
  mounted() {
    this.load();
    this.locationDeviceTableRef = (this.$refs as any)[
      "locationDeviceTable"
    ].uid;
  },
  watch: {
    id: function () {
      this.load();
    },
  },
  computed: {
    isEditing() {
      return this.id != null;
    },
    locationDeviceTableConfig() {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn(
          "deviceTemplate",
          this.$t("deviceTemplate", 'lb'),
          CxDataType.GetName
        ),
        new CxTableColumn("deviceRole", this.$t("deviceRole", 'lb'), CxDataType.Custom),
        new CxTableColumn("osName", this.$t("os", "lb")),
        new CxTableColumn("state", "", CxDataType.Custom),
      ]);
    },
    scheduleTableConfig() {
      let tc = new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("jobType", this.$c("jobType")),
        new CxTableColumn("lastExecutionTime", "", CxDataType.Custom),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
      return tc;
    },
    pnpRequestTableConfig() {
      return new CxTableConfig([
        new CxTableColumn("serialNumber", this.$c("serialNumber")),
        new CxTableColumn("correlator", this.$c("correlator")),
        new CxTableColumn("fileName", this.$c("fileName")),
        new CxTableColumn("successful", this.$c("successful"), CxDataType.Checkbox),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
    },
    subnetTableConfig() {
      return new CxTableConfig([
        new CxTableColumn("locationName", this.$t('location', 'lb')),
        new CxTableColumn("vlanName", this.$t('vlan', 'lb')),
        new CxTableColumn("ipPoolName", this.$t('ipPool', 'lb')),
        new CxTableColumn("subnet", this.$c('subnet'), CxDataType.Custom),
      ]);
    },
    categoryHasRoles() {
      return this.states.length < 1;
    },
  },
  beforeUnmount() {
    clearInterval(this.locationDeviceInteval)
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    this.$cx.notifyUnsavedChanges(next, this.initialState, this.entity);
  },
  methods: {
    load() {
      if (this.isEditing) {
        this.$store
          .dispatch("location/getById", this.id)
          .then((entity) => {
            this.entity = entity;
            this.initialState = this.$cx.getState(this.entity);
            this.loadLocationSubnets()
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("loading")));

        this.loadSchedules();
        this.loadStates();
        this.loadPnpRequests();
      }
    },
    loadLocationDevicesWithFilter(filters: any) {
      if (!this.isEditing) return;
      filters.id = this.id;
      clearInterval(this.locationDeviceInteval)
      let fn = () => {
        this.$store
          .dispatch("locationDevice/getWithFilter", filters)
          .then((locationDevices) => {
            this.locationDeviceData = locationDevices;
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
      }
      this.locationDeviceInteval = setInterval(fn, 5000)
      fn()
    },
    async save(goBack: Boolean) {
      if (await this.$cx.notifyValidationError(this.v$)) return;

      if (this.isEditing) {
        this.$store
          .dispatch("location/save", this.entity)
          .then(() => {
            this.$cx.notifySaved(this.$t("location", "lb"));
            this.initialState = this.$cx.getState(this.entity);
            if (goBack) this.$cx.goTo("location");
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      } else {
        this.$store
          .dispatch("location/create", this.entity)
          .then((newEntity: LocationDto) => {
            this.$cx.notifyCreated(this.$t("location", "lb"));
            this.entity = newEntity;
            this.initialState = this.$cx.getState(this.entity);
            if (goBack) this.$cx.goTo("location");
            else this.$cx.goToById("locationEditor", newEntity.id!);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
      }
    },

    onOpenAddDeviceDialogCreate() {
      this.isAddDeviceDialogVisible = true;
      this.$nextTick(() => this.deviceApi.reset());
    },
    onOpenAddDeviceDialogEdit(id: number) {
      this.isAddDeviceDialogVisible = true;
      this.$nextTick(() => this.deviceApi.load(id));
    },
    onSaveDevice() {
      this.deviceApi.onSave(() => {
        this.isAddDeviceDialogVisible = false;
        this.loadLocationDevicesWithFilter(
          (this.$refs as any)["locationDeviceTable"].tableFilter
        );
        this.loadStates();
      });
    },
    onCancelAddDeviceDialog() {
      this.isAddDeviceDialogVisible = false;
      this.loadLocationDevicesWithFilter(
        (this.$refs as any)["locationDeviceTable"].tableFilter
      );
    },

    onGenerateConfigsForLocation(override = false) {
      this.$store
        .dispatch("location/generate", { id: this.id, override: override })
        .then((result: CxLocationConfigResult) => {
          if (result.errors!.length > 0) {
            this.$cx.error(
              this.$cx.e("generating"),
              result.errors!.map((e) => e.message?.substring(0, 45)).join("\n")
            );
          }
          if (result.generatedConfigForDevices!.length > 0) {
            this.$cx.success(
              this.$t("location", "generatedSuccess"),
              result.generatedConfigForDevices!.map((g) => g.name).join("\n")
            );
          }
          if (
            result.generatedConfigForDevices!.length == 0 &&
            result.errors!.length == 0
          ) {
            this.$cx.success(
              this.$t("location", "generatedUpToDate"),
              this.$t("location", "generatedUpToDateDetail")
            );
          }
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("generating")));
    },

    onGenerateConfigForLocationDevice(id: number, name: string, save: boolean, override: boolean) {
      localStorage.setItem("cx.lastCompiledLocationDeviceName", name);
      localStorage.setItem("cx.lastCompiledLocationDeviceId", id.toString());
      let store = save
        ? "locationDevice/generateAndSave"
        : "locationDevice/generate";
      if (save) this.isGeneratingConfigByDeviceIdMap[id] = true
      this.$store
        .dispatch(store, { id: id, override: override })
        .then((config: CxConfigResult) => {
          if (save) {
            this.$cx.success(
              this.$t("locationDevice", "generatedSuccess"),
              name
            );
            return;
          }
          (this.$refs as any)["configDialog"].show(
            { id: id, name: name },
            config
          );
        })
        .finally(() => {
          delete this.isGeneratingConfigByDeviceIdMap[id]
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("generating")));
    },

    onDeleteLocationDevice(data: any) {
      (<any>this.$refs)["confirmDelete"].onDelete(data.id, () => {
        this.$cx.notifyDeleted(this.$t("locationDevice", "lb"));
        this.loadLocationDevicesWithFilter(
          (this.$refs as any)["locationDeviceTable"].tableFilter
        );
        this.loadStates();
      });
    },

    isGeneratingDeviceConfig(id: number) {
      return id in this.isGeneratingConfigByDeviceIdMap
    },

    // LocationDevice Config Diffs
    onGetLocationConfigDiffs() {
      this.$store
        .dispatch("location/getLocationConfigDiffs", this.id)
        .then((result) => {
          this.configCompareResult = result.map((m: any) => {
            let state = "identisch";
            let css = "";

            if (m.configurations.length == 0) {
              state = "leer";
              css = "color: grey";
            } else if (m.configurations.length == 1) {
              if (m.configurations[0].text.length < 2) {
                state = "leer";
                css = "color: grey";
              } else {
                state = "identisch";
                css = "color: grey";
              }
            } else if (m.configurations.length == 2) {
              if (m.configurations[0].text != m.configurations[1].text) {
                state = "verändert";
                css = "color: orange";
              }
            }

            return {
              ...m,
              ...{
                state: state,
                css: css,
              },
            };
          });
          this.isConfigCompareDialogVisible = true;
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    onCompareConfigSelected(ev: any) {
      this.selectedCompareDevice = ev.value;
      if (ev.value.configurations.length > 1) {
        this.sourceConfig = ev.value.configurations[0];
        this.targetConfig = ev.value.configurations[1];
      } else if (ev.value.configurations.length > 0) {
        this.sourceConfig = ev.value.configurations[0];
        this.targetConfig = ev.value.configurations[0];
      } else {
        this.sourceConfig = {};
        this.targetConfig = {};
      }
    },

    // States

    getStateClass(state: LocationStateDto) {
      if (state.minCount! > state.locationDeviceCount!) return "state-red";
      if (state.maxCount! < state.locationDeviceCount!) return "state-red";
      return "state-green";
    },

    loadStates() {
      this.$store
        .dispatch("location/getState", this.id)
        .then((data) => {
          this.states = data;
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },

    // Schedules
    loadSchedules() {
      this.$store
        .dispatch("schedule/getAllByLocationId", this.id)
        .then((schedules) => {
          this.schedules = schedules;
        })
        .catch((error) => console.error(error));
    },
    onTabChanged(ev: any) {
      clearInterval(this.schedulerInterval);
      if (ev.index == 2) {
        this.schedulerInterval = setInterval(() => this.loadSchedules(), 10000);
      }
    },

    // PnpRequests
    loadPnpRequests() {
      this.$store
        .dispatch("pnpRequest/getPnpRequestsByLocationId", this.id)
        .then((pnpRequests) => {
          this.pnpRequestData = pnpRequests;
        })
        .catch((error) => console.error(error));
    },

    // LocationSubnets
    loadLocationSubnets() {
      this.entity.locationTypes?.forEach((lt) => {
        this.$store
          .dispatch("location/getLocationSubnets", {
            locationId: this.id,
            locationTypeId: lt.id!,
          })
          .then((data) => {
            this.subnets[lt.name!] = data;
          });
      });
    },

    getMinLabel(state: LocationStateDto) {
      if (state.locationDeviceCount! < state.minCount!)
        return `(min. ${state.minCount})`;
      if (state.locationDeviceCount! > state.maxCount!)
        return `(max. ${state.maxCount})`;
    },
    onEditLocationCategory() {
      if (this.entity.locationCategory != null)
        this.$cx.goToByIdNewTab(
          "locationCategoryEditor",
          this.entity.locationCategory!.id!
        );
    },

    getDeviceStateClasses(state: string) {
      switch (state) {
        case "Provisioned":
          return "pi pi-check mr-1"
        case "Planned":
          return "pi pi-hourglass mr-1"
        case "Onboarding":
          return "pi pi-spin pi-cog mr-1"
        case "Executing Workflow":
          return "pi pi-spin pi-spinner mr-1"
        case "Not Contacted":
          return "pi pi-question mr-1"
        case "Initialized":
          return "pi pi-bolt mr-1"
        case "Authenticated":
          return "pi pi-key mr-1"
        case "Unclaimed":
          return "pi pi-power-off mr-1"
        default:
          return "pi pi-question mr-1"
      }
    }
  },
});
