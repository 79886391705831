import { apiExtender } from "@/_config/api-extender";
import { App } from "vue";
import Menu from "primevue/menu";
import PanelMenu from 'primevue/panelmenu';
import Card from "primevue/card";
import Tree from 'primevue/tree';
import Toast from 'primevue/toast';
import Badge from 'primevue/badge';
import Chart from 'primevue/chart';
import Steps from 'primevue/steps';
import Button from "primevue/button";
import Avatar from "primevue/avatar";
import Column from "primevue/column";
import Dialog from 'primevue/dialog';
import Slider from 'primevue/slider';
import Divider from "primevue/divider";
import Toolbar from "primevue/toolbar";
import TabView from "primevue/tabview";
import PrimeVue from "primevue/config";
import Tooltip from 'primevue/tooltip';
import Listbox from 'primevue/listbox';
import Checkbox from 'primevue/checkbox';
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import TabPanel from 'primevue/tabpanel';
import PickList from 'primevue/picklist';
import Password from 'primevue/password';
import Splitter from 'primevue/splitter';
import Calendar from 'primevue/calendar';
import Skeleton from 'primevue/skeleton';
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Accordion from 'primevue/accordion';
import TreeTable from 'primevue/treetable';
import { FilterService } from 'primevue/api';
import FileUpload from 'primevue/fileupload';
import TreeSelect from 'primevue/treeselect';
import MultiSelect from 'primevue/multiselect';
import SplitButton from 'primevue/splitbutton';
import InputNumber from 'primevue/inputnumber';
import RadioButton from "primevue/radiobutton";
import InputSwitch from 'primevue/inputswitch';
import ProgressBar from 'primevue/progressbar';
import ToggleButton from 'primevue/togglebutton';
import OverlayPanel from 'primevue/overlaypanel';
import ToastService from 'primevue/toastservice';
import AutoComplete from 'primevue/autocomplete';
import AccordionTab from 'primevue/accordiontab';
import SelectButton from 'primevue/selectbutton';
import ConfirmDialog from 'primevue/confirmdialog';
import SplitterPanel from 'primevue/splitterpanel';
import BadgeDirective from 'primevue/badgedirective';
import ProgressSpinner from "primevue/progressspinner";
import VirtualScroller from 'primevue/virtualscroller';
import OrganizationChart from 'primevue/organizationchart';
import ConfirmationService from 'primevue/confirmationservice';

import * as validators from "@vuelidate/validators";

import cx, { cxUtils } from "@/types/cx-utils";
import { Language } from "@/types/dto";
import LANG_DE from "./de.json"
import LANG_FR from "./fr.json"
import LANG_EN from "./en.json"
import VueDiff from 'vue-diff';
import { auth } from "./auth";
import { api } from "./api";
import Paginator from "primevue/paginator";

export const defaultLocale = cxUtils.getStorage("cx.lang", Language.EN)
let localeMap: { [key: string]: any } = {}
localeMap[Language.DE] = LANG_DE;
localeMap[Language.EN] = LANG_EN;
localeMap[Language.FR] = LANG_FR;
(<any>api.defaults.headers)["LANG"] = defaultLocale;
(<any>apiExtender.defaults.headers)["LANG"] = defaultLocale;

/**
 * Changes the display language at runtime
 * @param $primevue this.$primevue
 * @param language Language.EN
 */
export function switchLocale($primevue: any, language: Language) {
  $primevue.config.locale = localeMap[language];
  (<any>api.defaults.headers)["LANG"] = language;
  (<any>apiExtender.defaults.headers)["LANG"] = language;
  localStorage.setItem("cx.lang", language.toString());
}

// Register custom filters for DataTable, ...
FilterService.register("NameFromArray", (value: any, filter: any): boolean => {
  if (filter === undefined || filter === null || filter.trim() === '') return true
  if (value === undefined || value === null) return false
  if (!Array.isArray(value)) return false
  if (value.length < 1) return false

  for (let item of value) {
    if ("name" in item) {
      if (item.name != null)
        if (item.name.toLowerCase().includes(filter.toLowerCase())) return true
    }
  }

  return value.length < 1
})

export function loadUiFramework(app: App) {
  app.use(PrimeVue, {
    locale: localeMap[defaultLocale],
  });
  app.component("Menu", Menu);
  app.component("PanelMenu", PanelMenu);
  app.component("Card", Card);
  app.component("Tree", Tree);
  app.component("Toast", Toast)
  app.component("Badge", Badge);
  app.component("Chart", Chart);
  app.component("Steps", Steps);
  app.component("Slider", Slider);
  app.component("Column", Column);
  app.component("Avatar", Avatar);
  app.component("Button", Button);
  app.component("Dialog", Dialog);
  app.component("Listbox", Listbox);
  app.component("Toolbar", Toolbar);
  app.component("Divider", Divider);
  app.component("TabView", TabView);
  app.component("Skeleton", Skeleton);
  app.component("Password", Password);
  app.component("Splitter", Splitter);
  app.component("Calendar", Calendar);
  app.component("Checkbox", Checkbox);
  app.component("PickList", PickList);
  app.component("Dropdown", Dropdown);
  app.component("TabPanel", TabPanel);
  app.component("Textarea", Textarea);
  app.component("TreeTable", TreeTable);
  app.component("Accordion", Accordion);
  app.component("DataTable", DataTable);
  app.component("InputText", InputText);
  app.component("FileUpload", FileUpload)
  app.component("TreeSelect", TreeSelect)
  app.component("ProgressBar", ProgressBar)
  app.component("InputNumber", InputNumber)
  app.component("MultiSelect", MultiSelect)
  app.component("InputSwitch", InputSwitch);
  app.component("SplitButton", SplitButton);
  app.component("RadioButton", RadioButton);
  app.component("OverlayPanel", OverlayPanel)
  app.component("ToggleButton", ToggleButton)
  app.component("SelectButton", SelectButton)
  app.component("AccordionTab", AccordionTab)
  app.component("AutoComplete", AutoComplete);
  app.component("ToastService", ToastService);
  app.component("SplitterPanel", SplitterPanel)
  app.component("ConfirmDialog", ConfirmDialog)
  app.component("VirtualScroller", VirtualScroller);
  app.component("ProgressSpinner", ProgressSpinner);
  app.component('Paginator', Paginator);
  app.component("OrganizationChart", OrganizationChart);
  app.use(ToastService);
  app.directive('tooltip', Tooltip);
  app.directive('badge', BadgeDirective);
  app.use(ConfirmationService);
  app.use(VueDiff);
  app.use(auth);
  app.use(cx);
}

/* Custom validation messages */
const { createI18nMessage } = validators;
const withI18nMessage = createI18nMessage({
  t: (path: string, params: any) => {
    return (cxUtils.app.config.globalProperties.$primevue as any).config.locale[path]
      .replace("{property}", cxUtils.r(params.property))
      .replace("{max}", params.max || 0)
  }
})

// extend as needed: https://vuelidate-next.netlify.app/validators.html
export const required = withI18nMessage(validators.required);
export const requiredIf = withI18nMessage(validators.requiredIf, {
  withArguments: true
})
export const maxLength = withI18nMessage(validators.maxLength, {
  withArguments: true,
});

export const vMaxLen = {
  name: 255,
  text: 255,
  postCode: 10,
  streetNumber: 10,
  description: 1024,
};
