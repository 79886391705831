
import { CxConfigPart, CxConfigPartLine, DayNEditorDto } from "@/types/dto";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        id: String,
        visible: {
            type: Boolean,
            default: false
        },
        deviceIds: {
            type: Array as PropType<Number[]>,
            default: []
        }
    },
    data() {
        return {
            editorData: [] as DayNEditorDto[],
            selectedDevice: null as DayNEditorDto | null,
            dialogVisible: false,
            hideUnchanged: false,
            hiddenConfigPartNames: [] as string[]
        };
    },
    computed: {
        parts() {
            // build a sequential array of config-parts where configA and configB are one row of comparison
            let items: { configA: CxConfigPart; configB: CxConfigPart }[] = []
            for (let i = 0; i < this.configPartsB.length; i++) {
                let configA = {
                    configName: 'None',
                    configWeight: -1,
                    text: 'Does not exist'
                } as CxConfigPart

                // configB has priority since it's weight may be different in terms of sort order
                let configB = this.versionB!.configParts![i]
                let matchedConfigA = null as any

                if (this.versionA != null && this.versionA.configParts != null) {
                    matchedConfigA = this.versionA!.configParts!.find((part: CxConfigPart) => part.configName! == configB.configName)
                }

                if (matchedConfigA != null) {
                    // take it into comparison, if it exists
                    configA = matchedConfigA
                } else {
                    configA.configName = configB.configName
                    configA.configWeight = configB.configWeight
                }

                items.push({
                    configA: configA,
                    configB: configB
                })
            }

            // append all parts that have been removed in the new configB
            for (let i = 0; i < this.configPartsA.length; i++) {
                let configB = {
                    removed: true,
                    configName: 'Removed',
                    configWeight: -1,
                    lines: [{ text: 'Part was removed', enabled: false, removed: true }] as any[]
                } as CxConfigPart

                let configA = this.versionA!.configParts![i]
                let matchedConfigA = items.find((item: any) => item.configA.configName == configA.configName!)
                if (matchedConfigA == null) {
                    configB.configName = configA.configName
                    configB.configWeight = configA.configWeight
                    items.push({
                        configA: configA,
                        configB: configB
                    })
                }
            }

            // sort the array by configB weights and names
            items = items.sort((a: any, b: any) => {
                if (a.configB.configWeight !== b.configB.configWeight) {
                    return a.configB.configWeight! - b.configB.configWeight!;
                } else if (a.configB.configName !== b.configB.configName) {
                    return a.configB.configName!.localeCompare(b.configB.configName!);
                } else {
                    return 0;
                }
            })

            // hide parts by filtering by name
            items = items.filter((part: any) => !this.hiddenConfigPartNames.includes(part.configA.configName))

            return items
        },
        versionA() {
            if (this.selectedDevice != null && this.selectedDevice.productionConfig != null) {
                return this.selectedDevice.productionConfig
            }
            return null
        },
        versionB() {
            if (this.selectedDevice != null && this.selectedDevice.latestConfig) {
                return this.selectedDevice.latestConfig
            }
            return null
        },
        configPartsA() {
            if (this.versionA != null && this.versionA.configParts != null) return this.versionA.configParts!
            return []
        },
        configPartsB() {
            if (this.versionB != null && this.versionB.configParts != null) return this.versionB.configParts!
            return []
        }
    },
    methods: {
        load() {
            this.$store.dispatch("configuration/getDayNEditorData", this.deviceIds)
                .then((data: DayNEditorDto[]) => {
                    this.editorData = data
                    if (this.selectedDevice != null) {
                        this.selectedDevice = this.editorData.find((d: DayNEditorDto) => d.locationDeviceId == this.selectedDevice!.locationDeviceId!) as any
                    }
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
        },
        onChangeConfigPart(part: CxConfigPart) {
            if (part.lines != null) {
                part.lines.forEach((line: CxConfigPartLine) => {
                    line.enabled = part.enabled
                })
            }
        },
        onChangeConfigPartLine(part: CxConfigPart, line: CxConfigPartLine, index: number) {
            if (line.enabled && !part.enabled) {
                part.enabled = true
            }
            if (!line.enabled) return
            if (this.$cx.isNullOrEmpty(line.text)) return

            // enable all parent lines that nest this line
            // check if there is whitespace (if this line is a child)
            if (this.$cx.hasLeadingWhitespace(line.text!)) {
                let startLevel = this.$cx.countLeadingWhitespace(line.text!)
                // loop lines backwards
                for (let i = index; i >= 0; i--) {
                    let prevLine = part.lines![i]
                    // skip blank lines
                    if (this.$cx.isNullOrEmpty(prevLine.text)) continue
                    let endLevel = this.$cx.countLeadingWhitespace(prevLine.text!)
                    // compare indentation
                    if (startLevel > endLevel) {
                        prevLine.enabled = true
                        startLevel = endLevel
                    }
                }
            }
        },
        onSaveChanges() {
            let configs = [] as any[]
            this.editorData.forEach((change: DayNEditorDto) => {
                configs = configs.concat(change.latestConfig)
            })
            this.$store.dispatch("configuration/saveDayNEditorData", { configChanges: configs })
                .then(() => {
                    this.$cx.success(this.$t("dayn", "changesSaved"), "");
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("error")));
        },
        getDayNChanges() {
            if (this.selectedDevice == null) return
            this.$store.dispatch("configuration/getDayNChanges", {
                productionConfig: this.selectedDevice.productionConfig,
                latestConfig: this.selectedDevice.latestConfig
            }).then((data: any) => {
                if (data != null && data != "") {
                    this.selectedDevice!.latestConfig = data
                    this.onHideUnchangedChanged()
                }
                this.$cx.success(this.$t("dayn", "changesLoaded"), "");
            })
                .catch((error) => this.$cx.error(error, this.$cx.e("error")));
        },
        show() {
            this.dialogVisible = true
            this.selectedDevice = null
            this.hiddenConfigPartNames = []
            this.editorData = []
            this.load()
        },
        hide() {
            this.dialogVisible = false
        },
        onHideUnchangedChanged() {
            this.hiddenConfigPartNames = []
            if (this.hideUnchanged) {
                this.parts.forEach((pair: any) => {
                    let lines = pair.configB.lines!.filter((line: CxConfigPartLine) => line.enabled);
                    if (lines.length < 1) {
                        this.hiddenConfigPartNames.push(pair.configB.configName!)
                    }
                })
            } else {
                this.hiddenConfigPartNames = []
            }
        },
        onGenerateConfig(override = false) {
            this.$store
                .dispatch("locationDevice/generateAndSave", { id: this.selectedDevice!.locationDeviceId!, override: override })
                .then(() => {
                    this.$cx.success(
                        this.$t("locationDevice", "generatedSuccess"),
                        this.selectedDevice!.locationDeviceName!,
                    );
                    this.load()
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("generating")));
        },
        checkAndSetLineDisabled(line: any) {
            let disabled = false;
            if (line.removed) disabled = true;
            if (this.$cx.isNullOrEmpty(line.text)) disabled = true;
            if (disabled) line.enabled = false;
            return disabled
        }
    }
});
