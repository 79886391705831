import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex w-full" }
const _hoisted_2 = { class: "flex-1 flex justify-content-between" }
const _hoisted_3 = { class: "flex mr-4" }
const _hoisted_4 = { class: "grid" }
const _hoisted_5 = { class: "col-2" }
const _hoisted_6 = { class: "col-10" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  class: "cf-container",
  style: {"height":"calc(100vh - 320px)"}
}
const _hoisted_9 = { class: "flex-1 cf-card relative" }
const _hoisted_10 = {
  class: "flex-1 ml-2 cf-card relative",
  style: {"overflow-x":"hidden"}
}
const _hoisted_11 = ["onUpdate:modelValue", "onChange", "disabled"]
const _hoisted_12 = { style: {"border":"1px solid #a19f9d","padding":"7px 0px"} }
const _hoisted_13 = ["onChange"]
const _hoisted_14 = ["onUpdate:modelValue", "disabled"]
const _hoisted_15 = { style: {"white-space":"pre"} }
const _hoisted_16 = { class: "dayn-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_SplitButton = _resolveComponent("SplitButton")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.dialogVisible,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialogVisible) = $event)),
    modal: "",
    header: "Edit Day-N Config Parts",
    style: { width: '90vw' },
    contentStyle: { minHeight: '72vh' }
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.$t('dayn', 'lb')), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('dayn', 'hideUnchanged')), 1),
          _createVNode(_component_InputSwitch, {
            class: "ml-2",
            modelValue: _ctx.hideUnchanged,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hideUnchanged) = $event)),
            onChange: _ctx.onHideUnchangedChanged
          }, null, 8, ["modelValue", "onChange"])
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_SplitButton, {
          label: _ctx.$t('locationDevice', 'generateConfig'),
          icon: "pi pi-bolt",
          menuButtonIcon: "pi pi-cog",
          disabled: _ctx.selectedDevice == null,
          onClick: _cache[2] || (_cache[2] = () => _ctx.onGenerateConfig(false)),
          model: [
                        {
                            label: 'Override',
                            command: () => _ctx.onGenerateConfig(true)
                        }
                    ]
        }, null, 8, ["label", "disabled", "model"]),
        _createVNode(_component_Button, {
          onClick: _ctx.getDayNChanges,
          label: _ctx.$t('dayn', 'detectChanges'),
          disabled: _ctx.selectedDevice == null
        }, null, 8, ["onClick", "label", "disabled"]),
        _createVNode(_component_Button, {
          type: "button",
          label: _ctx.$t('dayn', 'save'),
          onClick: _ctx.onSaveChanges,
          disabled: _ctx.selectedDevice == null
        }, null, 8, ["label", "onClick", "disabled"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Listbox, {
            modelValue: _ctx.selectedDevice,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedDevice) = $event)),
            options: _ctx.editorData,
            listStyle: "max-height:calc(100vh - 350px)",
            filter: "",
            optionLabel: "locationDeviceName"
          }, {
            option: _withCtx((props) => [
              _createElementVNode("span", null, [
                _createElementVNode("b", null, _toDisplayString(props.option.locationDeviceName) + " (" + _toDisplayString(props.option.locationName) + ")", 1)
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "options"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.versionA != null && _ctx.versionB != null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", null, [
                  _createElementVNode("p", null, [
                    _createElementVNode("b", null, "Version " + _toDisplayString(_ctx.versionA.version || ' (None)') + " - Version " + _toDisplayString(_ctx.versionB.version || ' (None)'), 1)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parts, (part, i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "flex flex-nowrap mr-2 mb-2",
                        style: {"overflow-x":"hidden"},
                        key: i
                      }, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("p", null, _toDisplayString(part.configA.configName) + " (" + _toDisplayString(_ctx.$c('weight')) + ": " + _toDisplayString(part.configA.configWeight) + ")", 1),
                          _createVNode(_component_Textarea, {
                            readonly: true,
                            "auto-resize": true,
                            value: part.configA.text
                          }, null, 8, ["value"])
                        ]),
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("p", null, [
                            _withDirectives(_createElementVNode("input", {
                              type: "checkbox",
                              "onUpdate:modelValue": ($event: any) => ((part.configB.enabled) = $event),
                              onChange: ($event: any) => (_ctx.onChangeConfigPart(part.configB)),
                              disabled: (part.configB as any).removed
                            }, null, 40, _hoisted_11), [
                              [_vModelCheckbox, part.configB.enabled]
                            ]),
                            _createTextVNode(" " + _toDisplayString(part.configB.configName) + " (" + _toDisplayString(_ctx.$c('weight')) + ": " + _toDisplayString(part.configB.configWeight) + ")", 1)
                          ]),
                          _createElementVNode("div", _hoisted_12, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(part.configB.lines, (line, l) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: l,
                                onChange: ($event: any) => (_ctx.onChangeConfigPartLine(part.configB, line, l))
                              }, [
                                _withDirectives(_createElementVNode("input", {
                                  type: "checkbox",
                                  "onUpdate:modelValue": ($event: any) => ((line.enabled) = $event),
                                  disabled: _ctx.checkAndSetLineDisabled(line)
                                }, null, 8, _hoisted_14), [
                                  [_vModelCheckbox, line.enabled]
                                ]),
                                _createElementVNode("span", _hoisted_15, _toDisplayString(line.text), 1)
                              ], 40, _hoisted_13))
                            }), 128))
                          ])
                        ])
                      ]))
                    }), 128))
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}