import { ApiParamDto, CxEntityType } from "@/types/dto";
import CxBaseStoreModule from "@/types/cx-store-module";
import { api } from "@/_config/api";

const url = "eox"

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  actions: {
    async getByPage(context: any, props: any) {
      context.state.loading = true;
      try {
        let response = await api.get((url + `/`),{
            params: {
              page: props.page,
              size: props.size,
            }
          }
        );
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async getEoxForDevice(context: any, deviceId: number) {
      context.state.loading = true;
      try {
        let response = await api.get(url + `/device/${deviceId}`);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async getEoxForDeviceModel(context: any, modelId: number) {
      context.state.loading = true;
      try {
        let response = await api.get(url + `/model/${modelId}`);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async saveEoxRecords(context: any) {
      context.state.loading = true;
      try {
        let response = await api.post(url + `/init`);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
  }
}
